.giftcard-template-container{
    .giftcard {
        &-template {
            &-setting{

            }
        }
    }
}
.mp {
    &-giftcard-preview {
        button {
            background: $colorPrimary;
            border: 0;
            padding: 1rem 1.5rem;
            color: $colorWhite;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    &-giftcard{
        &-html{
            iframe{
                width: 100%;
            }
        }
    }
    &-email {
        &-html{
            display: none;
        }
    }
}
.preview-giftcard{
    border: 1px solid rgba($colorBorder, 0.2);
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.07);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.07);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.07);
    max-width: 100%;
}
#preview-giftcard {

    &-top{
        left: 0 !important;
    }
    &-image {
        max-width: 100%;
    }
}
.giftcard {
    &-template {
        &-upload{
            display: none;
        }
        &-setting {

            .block{
                &-title{
                    display: none;
                }
            }
            &-content{
                padding: 2rem;
                margin-top: -1px;
                .disabled{
                    display: none;
                }
            }
            &-select{
                > div{
                    display: flex;
                }
            }
        }
        &-preview{
            width: 100%;
            height: auto !important;
            transform: scale(1) !important;
        }
        &-container{
            &-preview{
                min-width: 100%;

                height: auto;
            }
        }
    }
    &-design {
        &-button {
            span{
                padding: 0 .9rem .5rem;
                display: inline-block;
                width: 100%;
                font-size: 1.4rem;
            }
            &-container {
                padding: 0;
                display: inline-block;
                border: 1px solid $colorWhite;
                i{
                    display: none;
                    vertical-align: middle;
                    margin-top: 6px;
                    @include transition;
                }
                button{
                    cursor: pointer;
                    background: transparent;
                    border: 0;
                    @include fontDefault;
                }
                &.active{
                    border: 1px solid $colorPrimary;
                    color: $colorPrimary;
                    i{
                        margin-top: -12px;
                        transform: rotate(180deg);
                    }
                    button{
                        color: $colorPrimary;
                    }
                }
            }
        }
    }
    &-information{
        margin-bottom: 2rem;
        &-container{
            margin-bottom: 4rem;
        }
    }
    &-field{
        &-wrapper{
            .note{
                font-size: 1.4rem;
                line-height: normal;
            }
        }
    }
}
.owl {
    &-stage {
        @include flex;

        &-outer {
            text-align: left;
        }

    }
}
.category-gutschein{
    .page-wrapper{
        .product {
            &.media {
                width: 55.5rem;
            }
            &-info-main{
                width: calc(100% - 57.5rem);
            }
        }
    }
}
@media only screen and (max-width: #{$mediumSmallBreakpoint}) /* 768px */ {
    .category-gutschein{
        .page-wrapper{
            .product {
                &.media {
                    width: 100%;
                }
                &-info-main{
                    width: 100%;
                }
            }
        }
    }
    #preview-giftcard {
        &-from, &-to, &-message {
            width: 90% !important;
        }
    }
}