footer{
  .footer{
    &-top{
      padding: 2rem 0;
      border-top: 1px solid $colorBorder;
      @include contentMedium;
      ul{
        @include flexCenter;
      }
      li{
        @include flexCenter;
        margin: 0 2rem;
      }
      a{
        display: block;
        background-color:$colorLightGrey;
        border-radius: 100%;
        width: 50px;
        line-height: 50px;
        text-align: center;
      }
    }
    &-main{
      padding: 2rem;
      background:$colorLightGrey;
      @include contentMedium;
      &-container{
        @include flexBetween;
        align-items: center;

        ul{
          @include flex;
        }
        a{
          margin-right: 1rem;
        }
        form{
          @include flex;
          align-items: center;
          input{
            padding: 1.4rem 0.9rem;
          }
          .control, .field{
            margin: 0;
          }
          .action{
            margin-left: 1rem;
            &.primary{
              padding: 1.37rem 3rem;
            }
          }
          .label{
            display: none;
          }
        }
      }
    }
    &-bottom{
      background:$colorPrimary;
      color: $colorWhite;
      text-align: center;
      padding: 1rem 0;
      line-height: 1.25;
      @include contentMedium;
    }
  }
}
