header{
  @include contentMedium;
  .header{
    @include flexBetween;
    align-items: center;
    .contentarea{
      display: none;
    }
    &.panel{
      padding: 1rem 0;
      border-bottom: 1px solid $colorBorder;
    }
    &.links{
      order: 1;

      li{
        a{
          margin-left: 1.5rem;
        }
        &.compare{
          display: none;
        }
      }
    }
    .switcher {
      &-label {
        display: none;
      }
      &-options {
        position: relative;
        &.active{
          .switcher-dropdown{
            display: block;
          }
        }
      }
      &-dropdown{
        background: $colorWhite;
        border: 0.1rem solid $colorBorder;
        margin-top: 0.4rem;
        min-width: 16rem;
        z-index: 100;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: none;
        position: absolute;
        top: 100%;
        -webkit-box-shadow: 0 0.3rem 0.3rem $colorBorder;
        box-shadow: 0 0.3rem 0.3rem $colorBorder;
        li{
          font-size: 1.2rem;
        }
        a{
          display: block;
          padding: 0.8rem;
        }
      }
      &-trigger{
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        &:after{
          font-size: 1.2rem;
          line-height: 2rem;
          color: #333;
          @include fontIcon;
          font-weight: 400;
          content: "";
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          display: inline-block;
          margin-left: 1rem;
        }
        strong{
          font-weight: normal;
        }
      }
    }
    &.content{
      padding: 1.1rem 0;
      .nav-toggle{
        display: none;
      }
      .logo{
        width: 100%;
        text-align: center;
        img{
          width: auto;
        }
      }
      .block-search, .minicart-wrapper{
        width: 30%;
        input{
          font-size: 1.3rem;
        }
      }
    }
  }
}
.block-minicart{
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: $colorWhite;
  z-index: 2;
  padding: 2.5rem 2rem;
  box-shadow: 0 0.3rem 0.3rem $colorBorder;
  border: 1px solid $colorBorder;
  min-width: 39rem;
  .action{
    &.close{
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      background: transparent;
      width: 4rem;
      height: 4rem;
      @include flexCenter;
      &:before{
        @include fontIcon;
        content: "#{$iconClose}";
        font-size: 2rem;
      }
      span{
        display: none;
      }
    }
    &s{
      width: 100%;
      .primary, .secondary{
        cursor: pointer;
        padding:1.3rem 2.9rem;
        font-size: 1.4rem;
        /* @include fontMedium; */
        line-height: 1.7rem;
        @include defaultFastTrans;
        text-align: center;
        width: 100%;


      }
      .primary{
        color: $colorWhite;
        background-color: $colorSecondary;
        border: 1px solid $colorSecondary;
        font-weight: bold;
        &:hover {
          @include defaultTrans;
          background: transparent;
          color: $colorPrimary;
        }
      }
      .secondary{
        color: $colorPrimary;
        background-color: transparent;
        border: 2px solid $colorSecondary;
        &:hover {
          @include defaultTrans;
          background: transparent;
          color: $colorPrimary;
        }
      }
    }
  }
  .block{
    &-content{
      margin-top: 1.5rem;
      @include flexWrap;
    }
  }
  .items-, .sub {
    &total {
      width: 50%;
      @include flex;
      .price, .count{
        font-weight: bold;
      }
    }
  }

  .items-total{
    text-align: left;
    justify-content: flex-start;
  }
  .subtotal{
    text-align: right;
    justify-content: flex-end;
    .price{
      margin-left: .5rem;
    }

  }
}

