.mage-error {
  color: $colorPrimary;
}
.messages {

  .message{
    margin: 1.5rem 0 ;
    position: relative;
    padding: 1.5rem 2rem;
    padding-left: 4.5rem;
  }
  >*{
    //margin-bottom: 3rem;
  }
}
.page.messages{
  @include contentMedium;
}
.message {
  display: block;
  margin: 0 auto;
  position: relative;
  padding: 1.5rem 2rem;
  padding-left: 4.5rem;
  > *:first-child:before {
    font-size: 2.4rem;
    line-height: 2.4rem;
    @include fontIcon;
    left: 0;
    top: 1.2rem;
    width: 4.5rem;
    position: absolute;
    text-align: center;
  }
  &.error {
    background: $colorError;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconError}";
      }
    }

  }
  &.success {
    background: $colorSuccess;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconError}";
      }
    }
  }
  &.info {
    background: $colorGrey;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconInfo}";
      }
    }
  }
  &.empty {
    background: $colorPrimary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconInfo}";
      }
    }
  }
  &.warning {
    background: $colorPrimary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconWarning}";
      }
    }
  }
  &.notice {
    background: $colorPrimary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconWarning}";
      }
    }
  }
  &.success,
  &.notice,
  &.warning,
  &.info,
  &.empty,
  &.error {
    a {
      color: $colorWhite;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: $colorWhite;
        text-decoration: underline;
      }
    }
  }
}
.cms-home{
  .messages{
    margin-bottom: 0;
  }
}
.checkout-index-index{
  .messages{
    width: 100%;
    max-width: calc(100% - 39rem);
    @media only screen and (max-width: #{$mediumBreakpoint}) /* 1024px */ {
      max-width: 100%;
    }
    .message{
      &.error{
        margin: 0;
        width: 100%;
        max-width: 95.2rem;
        margin-bottom: 20px;
      }

    }
  }
  .message{
    &.notice{
      margin-bottom: 3rem;
    }
  }
}
.field-error, .mage-error {
  color: $colorError;
  font-size: 1.4rem;
  width: 100%;
}