button{
  cursor: pointer;
}
.page-main, .footer-main, .header-main, .cookie, #notice-cookie-block, .modals-wrapper{
  .secondary {
    @include flex;
    align-items: center;
    justify-content: center;
  }
  .primary + .secondary{
    .action {
        margin-left: 2rem;
      display: inline-block;
        @media only screen and (max-width: #{$mobileBreakpoint}) /* 480px */ {
          margin-left: 0;
          margin-top: 2rem;
        }
    }
  }
  .action + .action{
    margin-left: 2rem;
    @media only screen and (max-width: #{$mobileBreakpoint}) /* 480px */ {
      margin-left: 0;
      margin-top: 2rem;
    }
  }
  .action {
    cursor: pointer;
    padding:1.3rem 2.9rem;
    font-size: 1.4rem;
    /* @include fontMedium; */
    line-height: 1.7rem;
    @include defaultFastTrans;
    color: $colorPrimary;
    background-color: transparent;
    border: 2px solid $colorSecondary;
    text-align: center;
    font-weight: bold;
    &:hover {
      @include defaultTrans;
      background: $colorSecondary;
      color: $colorWhite;
    }
    &.primary, &.tocart.primary, &.allow, &-update  {
      color: $colorWhite;
      background-color: $colorSecondary;
      border: 1px solid $colorSecondary;
      font-weight: bold;
      &:hover {
        @include defaultTrans;
        background: transparent;
        color: $colorPrimary;
      }
      &.tocart.disabled {
        border-color: $colorSuccess;
        background-color: $colorSuccess;
      }

    }

    &.secondary, &.action-edit-address , &.action.edit-address-link, &.tocategory.secondary, &.totag.secondary, &.back, &.remind, &.tocart, &.reload, &.clear, &.update, &-hide-popup, &-show-popup,, &-cancel, &.share {
      color: $colorPrimary;
      background-color: transparent;
      border: 2px solid $colorSecondary;
      text-align: center;
      padding: 1.3rem 2.9rem;
      font-weight: bold;
      &:hover {
        @include defaultTrans;
        background: $colorSecondary;
        color: $colorWhite;
      }
    }
    @media only screen and (max-width: #{$mediumBreakpoint}) {
      margin-right: 0;
    }
    @media only screen and (max-width: #{$mediumSmallBreakpoint}) {
      letter-spacing: 0;
      line-height: normal;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-align: center;
    }
    &.disabled {
      pointer-events: all;
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.view{
      border:0;

      span{
        display: none;
        font-size: 1.3rem;
      }
      &:before{
        content: $iconEye;
        @include fontIcon
      }
      &:hover{
        background: transparent;
        color: $colorPrimary;
        font-weight: normal;
      }
    }
  }
}
#minicart-content-wrapper .secondary .action, a.action.print, .block-dashboard-orders .block-title.order a.action.view {

/*  padding:1.5rem 4rem;*/
  font-size: 1.2rem;

  color: $colorPrimary;
  background-color: transparent;
  border: 0.1rem solid $colorPrimary;

  @include defaultFastTrans;
  &:before{
    display: none;
  }
  span{
    display: block;
  }

}
.block-dashboard-orders .order .action.view {
 /* position: absolute;
  right: 0;
  bottom: 1.5rem;
  margin-right: 0;*/
  margin-left: 2rem;
  @media only screen and (max-width: #{$mobileBreakpoint}) /* 480px */ {
    margin-top: 2rem;
    margin-left: 0;
  }
}
.emptyButton {
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  margin-left: 0.1rem;
  &:hover {
    margin-left: 0rem;
    border: none;
  }
}
.top-container .overlay .action{
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: $colorWhite;
  padding: 1.4rem 2rem;
  border: 0.2rem solid $colorWhite;
  background-color: transparent;
  margin: 0.2rem;
  &:hover {
    margin: 0;
    background-color: $colorWhite;
  }
}
.page {
  &-main, &-header {
    .action {
      &.delete, &-delete{
        text-decoration: none;
        max-width: inherit;
        min-height: inherit;
        margin: 0;
        padding: 0;
        border: none;
        > span {
          height: 0.1rem;
          overflow: hidden;
          position: absolute;
          width: 0.1rem;
        }
        &:before {
          height: 1.6rem;
          width: 1.6rem;
          font-weight: 300;
          @include fontIcon;
          content: "#{$iconTrash}";
          font-size: 2rem;
          color: $colorPrimary;
          display: block;
        }
        &:hover{
          background: transparent;
          color: initial;
        }
      }
    }
  }
  &-main{
    @media only screen and (max-width: #{$mobileBreakpoint})/* 1440px */ {
      .primary, .secondary{
        width: 100%;
      }
      .action{
        margin-right: 0;
        width: 100%;
        padding: 1rem;
      }
    }

    .action{
      &s-toolbar {
        @include flex;
        /*> .primary {
          margin-right: 1.5rem;
        }*/
      }
      &.edit, &-edit, &.change-password, &-change-password{
        text-decoration: none;
        max-width: inherit;
        min-height: inherit;
        margin: 0;
        padding: 0;
        border: none;
        font-size: 0;
        letter-spacing: 0;

        > span{
          height: 0.1rem;
          overflow: hidden;
          position: absolute;
          width: 0.1rem;
          display: none;
        }
        &:before {
          @include fontIcon;
          @include icon;
          font-size: 1.8rem;
          width: auto;
          height: auto;
          padding-left: 1rem;
          color: $colorPrimary;
        }


      }
      &.edit, &-edit{
        &:before {
          content: "#{$iconEdit}";
        }
      }
      &.change-password, &-change-password{
        &:before {
          content: $iconKey;
        }
      }
    }

  }
}
.magento-widget.category-overview .widget-content {
  margin: 0 auto;
  display: flex;
  padding-bottom: 12rem;
  padding-top: 10rem;
  justify-content: center;
  a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
.action.submit.secondary:hover {
  margin: 0 0.1rem;
}

.product-info-category {
  ul li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }
    }
  }

}
.categories.list {
  li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }

    }
  }
}
