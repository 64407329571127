.minicart {
  &-items{
    .product{
      @include flex;
      font-size: 1.4rem;
      line-height: normal;
      padding-top: 2rem;
      &-item{
        width: 100%;
        padding-bottom: 0;
        &-inner{
          font-size: 1.3rem;
          strong{
            font-weight: 500;
            display: inline-block;
            width: 100%;
            margin-bottom: 1rem;
            text-align: left;
          }
        }
      }
      &-image-container {
        margin-right: .7rem;
      }
    }
    .subtotal{
      text-align: left;
    }
    .details{
      &-qty{
        text-align: left;
      }
    }
  }
  &-wrapper {
    text-align: right;
    position: relative;

    .load {
      &er {
        > img {
          max-width: 100%;
          min-width: initial;
          background: transparent;
          min-height: initial;
        }
      }

      &ing-mask {
        @extend .counter-number;
        position: absolute !important;
        /*width: 1.5rem;
        height: 1.5rem;
        top: -1.4rem;
        right: -0.45rem;*/
        left: initial;
        bottom: initial;
      }
    }

    .counter {
      position: relative;
    }

    ._block-content-loading {

    }

    > a {
      &:after {
        @include fontIcon;
        font-weight: 400;
        font-size: 2.5rem;
        color: #333;
        content: "#{$iconCart}";
      }

      .text {
        display: none;
      }

      .counter {
        &.empty {
          .counter {
            &-number {
              display: none;
            }
          }
        }

        &-number {
          background: $colorSecondary;
          display: inline-block;
          color: $colorWhite;
          width: 2rem;
          height: 2rem;
          font-size: 1.2rem;
          line-height: 2rem;
          text-align: center;
          position: absolute;
          top: -1.6rem;
          right: -0.7rem;
          border-radius: 50px;
          font-weight: 800;
          letter-spacing: -1px;
          /*&:before{
            content: "10";
            display: inline-block;
          }*/
        }

        &-label {
          display: none;
        }
      }

      ._block-content-loading {
        .counter-number {
          background: $colorWhite;
        }
      }
    }
  }
}