.catalog-product-view{
    .breadcrumbs{
      @extend .category-page-header;
      padding: 4rem 0;
    }
}
.breadcrumbs{
  font-size: 1.2rem;
  display: none;
  /* padding: 1.4rem 0;*/
  padding: 0;
  margin-bottom: 0;
  text-align: center;

  ul{
    @include flex;
    justify-content: center;
    li{
      font-weight: 500;
      margin-right: 0.6rem;
      color: $colorPrimary;
      &:after{
        content: "/";
      }
      a{
        color: $colorPrimary;
        margin-right: 0.6rem;
        display: inline-block;
      }
      strong{
        color: $colorBreadcrumbs;
      }
      &:last-child{
        &:after{
          content: "";
        }
      }
      > *{
        font-weight: normal;
      }
    }
  }
}