#notice-cookie-block{
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background-color: $colorWhite;
  max-width: $contentWidth;
  padding: 2rem;
  left: 0;
  right: 0;
  border: 0.2rem solid $colorPrimary;
  border-bottom: 0;
  .content {
  @include flexBetween;
    align-items: center;
    .actions {
      min-width: 22rem;
      text-align: right;
      button {
        margin-right: 0;
      }
     }

  }
  @media only screen and (max-width: 1024px) {
    .content {
      flex-wrap: wrap;
      .actions {
        padding-top: 1rem;
        text-align: left;
      }
    }
  }
}
