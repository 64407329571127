.page-products{
    .category-image{
      margin-bottom: 1.4rem;
    }
}
.details-wrapper{
  @include flexBetween;
}
.product {
  &-item {
    /*width: calc(25% - 1.125rem);*/
    width: calc((100% - 4.5rem) / 4);
    padding-bottom: 5rem;
    margin-right: 1.5rem;
    .price {
      &-filling-amount, &-details {
        display: none;
      }
    }
    &-info{
      &:hover{
        .product{
          &-item{
            &-overlay{
              opacity: 1;
            }
          }
        }
        img{
          transform: scale(1.1);
        }
      }
    }
    &:nth-of-type(4n){
      margin-right: 1.5rem;
    }
    &-image{
      position: relative;
      overflow: hidden;
      /*background-color: #f8f6f5;*/
      &:hover{

      }
    }
    &-photo{
      width: 100%;
      display: inline-block;
      span {
        display: block;
        max-width: 100%;
        overflow: hidden;

      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        vertical-align: top;
      }
    }
    &-overlay{
      position: absolute;
      bottom: 0;
      height: 100%;
      left: 0;
      width: 100%;
      @include flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      background: $colorSecondary;
      @include transition;
      .box-tocart{

        .action{
          &.primary{

            &:hover{
              background: $colorPrimary;
              color: $colorSecondary;
            }
          }
        }
      }
    }
    &-details{
      text-align: center;
      margin-top: .3rem;
      color: $colorPrimary;
      .filling_amount{
        line-height: normal;
        padding: 1rem 0;
      }
      a{
        color: $colorPrimary;
      }
    }
    &-price{
      .price{
        &-box{
          span.price-label {

          }
          .price {
            &-container {
              @include flex;
              align-items: center;
              justify-content: center;
            }

          }
          span.price {
            font-weight: normal;
            display: block;
            padding-left: 1rem;
          }
        }
        &-details {
          display: none;
        }
        &-per-amount{
          display: none;
          font-size: 1rem;
          line-height: 1.7rem;
          span{
            display: inline-block;
          }
        }
      }
    }
    &-brand {
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: 0.016rem;
      color: $colorSecondary;
      font-weight: 300;
      text-align: center;
    }
    &-name{
      font-weight: 700;
    }
    &-link{
      font-size: 1.5rem;
      a{

      }
    }
    &-short-description{
      font-size: 1.3rem;
      padding-top: 1.3rem;
      position: relative;
      padding-bottom: 2.5rem;
    }
  }
  &-image{
    &-container{
      position: relative;
    }
    &-wrapper{
      overflow: hidden;
      img{
        transform: scale(1.01);
        @include transition;
      }
    }
  }
}
.minicart-items{
  .product{
    &-item{

    }
  }
}
.products{
  /*@include contentMedium;*/
  &.list{
    @include flexStart;
    flex-wrap: wrap;
    justify-content: flex-start;
    .product {
      &-item {

        &:nth-of-type(4n){
          margin-right: 0;
        }
      }
    }
  }
}
.filling_amount{
  padding: 1.2rem 0;
  font-size: 1.6rem;
  color: $colorGrey;
}
.price{
  &-box{
   font-weight: 700;
    font-size: 2rem;

  }
  &-filling-amount, &-details{
   order:1;
  }

}
