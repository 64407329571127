.sidebar-main {
  width: calc(25% - 1.5rem);
  order: 1;
  .block{
    &-title{
      text-align: left;
      padding: 0;
      margin: .2rem 0 .6rem;
      justify-content: flex-start;
      text-transform: uppercase;
      line-height: normal;
      strong{
        padding:0 0 .5rem;
        border-bottom: 1px solid $colorBorder;
      }
    }
    &-content{
      .top{
        display: none;
      }
    }
    &-subtitle{
      display: none;
    }
  }
  #layered-category-block{
    ul{
      li{

        margin-bottom: 0.3rem;
      }
    }
  }
  .filter{
    &-options{

      &-title{
        padding-top: 1.6rem;
        font-size: 1.4rem;
        font-weight: bold;
        border-top: 1px solid $colorBorder;
        &:first-child{
          border-top: 0;
        }
      }
      &-content{
        padding-bottom: 1.6rem;
        ol{
          li{
            font-size: 1.4rem;
            margin: .7rem 0;
            a{
            }
            span{
              color: $colorPrimary;
              margin-right: .7rem;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .category {
    margin-bottom: 4rem;
    &-list{
      &.level {
        &-3 {
          &.active {
            .category-item.level-3:not(.active){
              opacity: 30%;
            }
          }
        }
        &-4{
          max-height: 0;
          overflow: hidden;
          @include defaultTrans;
          li {
            a {
              padding-bottom: 1.1rem;
              display: block;
              font-weight: bold;
              line-height: 2.9rem;
              text-align: center;
              margin-right: -0.8rem;
              &:after {
                /*  background-image: url($iconDir + "arrow_small.svg");*/
                @include icon;
                height: 0.8rem;
                width: 1.6rem;
              }
              &:before {
                display: none;
              }
            }
            &:last-child a {
              padding-bottom: 0;
            }
          }
        }
      }
    }
    &-item{
      &.level-3{
        border-bottom: 1px solid $colorLightGrey;
        min-height: 12.1rem;
        & > a {
          padding-top: 2.2rem;
          @include flexCenter;
          flex-direction: column;
          @include fontSmaller;
          font-weight: bold;
          line-height: 2.3rem;
          &:before {
            height: 4.5rem;
          }
        }
        &.active {
          a {
            font-weight: bold;
          }
        }
        &.active{
          > ul{
            max-height: 30rem;
            @include defaultTrans;
            padding-top: 2.4rem;
            padding-bottom: 3.8rem;
          }
        }
      }
    }
  }
}