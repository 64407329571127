.checkout{
  &-index{
    &-index{
      header {
        &-logo{

        }
        .header-content {
          height: auto;
          justify-content: center;
          padding: 1.4rem 0;
        }
      }
      .columns{
        padding-bottom: 6rem;
      }
      /*.page-main{
        padding-top: 5rem;
      }*/
    }
  }
  &-container{
    @include flexWrap;
    justify-content: space-between;
    padding-bottom: 6rem;
  }
  &-agreement {
    @include flex;
    button{
    }
    margin: 1rem 0;
    &:last-child{
      margin-bottom: 0;
    }
    input{
      width: auto;
    }
    .action{
      padding: 0;
      padding-left: 1.2rem;
      margin: 0;
      text-align: left;
      font-size: 1.4rem;
      background: transparent;
      color: $colorPrimary;
      border:0;
      &:hover{
        background: transparent;
        color: $colorPrimary;
      }

    }
    .label{
      max-width: calc(100% - 1.6rem);
    }
    &s {
      margin-bottom: 3rem;
    }
  }
  &-shipping{
    &-method{
      margin-top: 4rem;
    }
  }
  &-billing{
    &-address{
      margin-bottom: 3rem;
    }
  }
  &-additional-information{
    @extend .checkout-shipping-method;
  }
  &-onepage-success{
    .page-main .actions-toolbar{
      .action{
        &.primary{
          margin-top: 3rem;
          display: block;
        }
      }
    }
  }
}
.note{
  font-size: 1.4rem;
  line-height: normal;
}
.address{
 > .field{
   .control {
     input, select {
       width: 100%;
       margin-bottom: 0;
     }
   }
 }
}
.billing{
  &-address{
    &-details{
      background: $colorLightGrey;
      padding: 1.7rem 2rem;
      margin-top: 1rem;
      br{
        display: block;
      }
      span{
        display: block;
      }
      .action{
        display: block;
        margin-top: 1.5rem;
      }
    }
    &-same-as-shipping-block{
      @include flex;
      input{
        width: auto;
      }
      label{
        width: 100%;
        padding-left: 1rem;
        font-size: 1.4rem;
      }
    }
  }
}
.minicart{

}
#shipping-new-address-form{
  @include flexWrap;
  justify-content: space-between;
  > div{

  }
  > .field{

width: 100%;

  }
}
#checkout {
  &-shipping-method-load {
    background: $colorLightGrey;
    padding: 2rem;
    margin-bottom: 3rem;
    border: 1px solid $colorBorder;

    table {
      tbody {
        border-bottom: 0;

        tr {
          padding-bottom: .7rem;
          @include flex;
          margin-bottom: .7rem;
          border-bottom: 1px solid $colorBorder;

          &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
.step {
  &-title {
    font-weight: bold;
    line-height: 1;
    font-size: 2.4rem;
    padding-bottom: 1.6rem;
    display: block;
  }
  &-content{

    table.table-checkout-shipping-method{
      td{
        &.col{
          width: 100%;
          text-align: left;
          color: $colorPlaceholder;
          padding: 0;
          padding-left: 1rem;
          &-method{
            &:first-child{
              width:1.5rem;
              padding: 0;
            }
          }
          input{
            width: auto;
            color: $colorPlaceholder;
          }
        }
      }
    }
  }

}
.opc{
  &-estimated-wrapper{
    display: none;
  }
  &-progress-bar {
    padding-bottom: 5rem;
    padding-top: 5rem;
    width: 100%;
    @include flexBetween;
    counter-reset: i;
    max-width: calc(100% - 39rem);

    li {
      margin: 0 0 1rem;
      display: inline-block;
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 50%;
      /*max-width: 46.4rem;*/
      max-width: calc(50% - .7rem);

      &:before {
        background: $colorPrimary;
        top: 3.4rem;
        content: "";
        height: 0.1rem;
        left: 0;
        position: absolute;
        width: 100%;

      }

      &:first-child:before {
        border-radius: 0.6rem 0 0 0.6rem;

      }

      &:last-child:before {
        border-radius: 0 0.6rem 0.6rem 0;
      }

      > span {
        display: block;
        padding-bottom: 4.5rem;
        width: 100%;
        word-wrap: break-word;
        color: $colorPrimary;
        text-align: center;
        font-weight: normal;

        &:after {
          left: 50%;
          position: absolute;
          /*margin-left: -1.3rem;*/
          top: 2.3rem;
          content: counter(i);
          counter-increment: i;
          background: $colorWhite;

          border-radius: 50%;
          border: 2px solid $colorPrimary;
          height: 2.4rem;
          width: 2.4rem;
          font-size: 1.04rem;
          font-weight: 500;
          color: $colorPrimary;
          background-size: 70%;
          content: counter(i);
          counter-increment: i;
          @include flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          transform: translate(-50%, 0);

        }
      }

      &._active {
        opacity: 1;
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorPrimary;
          font-weight: 500;
          &:after {
            color: $colorSecondary;
            border-color: $colorSecondary;
          }
        }
      }

      &._complete {
        cursor: pointer;
        opacity: 1;
        &:before {
          background: $colorSecondary;
        }

        > span {
          color: $colorPrimary;

          &:after {
            content: "\f058";
            @include fontIcon;
            font-weight: 900;
            border-color: $colorSecondary;
            font-size: 24px;
            color: $colorSecondary;
          }
        }
      }
    }
  }
  &-wrapper{
    width: 100%;
    max-width: calc(100% - 39rem);
  }
  &-block {
    &-shipping{
      &-information{

        .action{
          background: transparent;
          &:hover{
            background: transparent;
          }
        }
      }
    }
    &-summary {

      table{
        @extend .totals;
        margin-top: 1.6rem;

        th, td {
          line-height: 1;
          padding: 0;
          font-size: 1.6rem;
          font-weight: normal;
          text-align: left;
        }
        tr{
          td{
            text-align: right;
          }
        }

      }
    }
  }
  &-sidebar{
    width: 33rem;
    margin-top: -10rem;
    header{
      display: none;
    }
  }
}
.shipping{
  &-address{
    &-item{
      margin-bottom: 1.5rem;
      margin-right: 2%;
      width: 32%;
      padding: 2rem;
      border: 1px solid $colorLighterGrey;
      &:nth-of-type(3n){
        margin-right: 0;
      }
      &.selected-item{
        border-color: $colorPrimary;
      }
      button.action{
        width: 100%;
        background: $colorPrimary;
        color: $colorWhite;
        margin-top: 2rem;
        margin-left: 0 !important;
        border-color: $colorPrimary;
        &:hover{
          color: $colorPrimary;
          background: transparent;
        }
      }
      &s{
        @include flexWrap;
      }
    }
  }
  &-information{
    margin-top: 2rem;
    &-title{
      font-weight: bold;
      padding-bottom: 1.3rem;
      border-bottom: 1px solid $colorBorder;
      margin-bottom: 1.4rem;
      @include flex;
      > span{
        &:first-child{
          width: 100%;
        }
      }

    }
  }
}
.ship{
  &-via{
    margin-top: 4rem;
  }
}
.items{
  &-in{
    &-cart{
      padding-top: 2rem;
      .title{
        cursor: pointer;
      }

    }
  }
}
.payment{
  &-method{
    padding: 1.7rem 0;
    border-bottom: 1px solid $colorBorder;
    &:last-child{
      border-bottom: 0;
      padding-bottom: 0;
    }
    &-title{

      @include flex;
      input{
        width: auto;
        margin-right: 1rem;
      }
    }
    &-content{
      display: none;
      padding-top: 2rem;
      form{

      }
    }
    &._active{
      .payment{
        &-method{
          &-content{
            display: block;
          }
        }
      }
    }
  }
  &-group{
    background: $colorLightGrey;
    padding: 2rem 2.3rem;
  }
  &s{
    .legend{
      @extend .title;
      border:0;
      @include fontTitle;
      font-weight: 600;
      font-size: 4rem;
    }
  }
  &-option {
    border-bottom: 1px solid $colorGrey;
    padding-bottom: 1.5rem;
    &-content{
      .messages{
        max-width: 100%;
        .message{
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
    &-title {
      margin: 1.5rem 0 0;
      .action{
        display: block;
        padding: 1rem 0;
        color: $colorPrimary;
        text-align: left;
        border:0;
      }
    }
  }
}


.amazon-button-container__cell{
  margin-top: 2rem;
}
