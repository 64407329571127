body{
  overflow: auto;
  overflow-x: hidden;
}
.nav-open{
  body{

  }
}
.page-products{
  .page-title-wrapper h1{
      @include font;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.home {
  .page-main {
    padding-top: 0;
  }
}
header, section, footer, aside, nav, main, article, figure {
  display: block;
}
/*Spide*/
.elementor-widget .widget-content .splide .splide__slide.is-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
}
.elementor-widget .widget-content .splide {
  visibility: visible !important;
}
ul.splide__pagination{
  bottom: 0;
  justify-content: center;
  li {
    button.is-active {
      background-color: $colorSecondary;
      transform: none;
      opacity: 1;


    }
    button {
      border-radius: 0;
      border: 0rem solid white;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      transition: none;
      opacity: 1;
      color: rgba($colorSecondary, 0.3);
      width: 5rem;
      height: 3px;
      &:before {
        color: transparent;
      }

    }
  }
}
.splide__arrow {
  &s{
    @include flex;
    position: absolute;
    top: -31px;
    right: 0;
  }
  &.splide__arrow {
    width: auto;
    height: auto;
    background-color: transparent;
    /*top: 40%;*/
    @include flex;
    align-items: center;
    font-size: 3rem;
    position: relative;
    display: inline-block;
    transform: translateY(0);
    top: inherit;
    left: inherit;

    &:before {
      @include fontIcon;
      height: auto;
      width: auto;
      display: inline-block;
      color: #AFAFAF;
    }

    &--prev {
      left: inherit;
      margin-right: 1rem;
      &:before {
        content: url($imageDir + "arrow_slide_left.svg");
      }
    }

    &--next {
      right: inherit;

      &:before {
        content: url($imageDir + "arrow_slide_right.svg");
      }
    }
  }
}

button.splide__arrow.splide__arrow--next, button.splide__arrow.splide__arrow--prev {
  svg {
    display: none;
  }
}
button.slick{
  &-prev, &-next{
    &.slick-arrow{
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 30px;
      height: 40px;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      z-index: 9;
      &:before{
        @include fontIcon;
        content: $iconArrow;
        color: $colorPrimary;
        transform: rotate(0deg);
      }
    }
  }
  &-prev{
    &.slick-arrow{
      left: 0;
      transform: rotate(-90deg);
      &:before{

      }
    }
  }
  &-next{
    &.slick-arrow{
      right: 0;
      transform: rotate(90deg);
      &:before{

      }
    }
  }
}


.to-top{
    position: fixed;
    bottom: 13rem;
    right: -6rem;
    width: 5.9rem;
    height: 5.9rem;
    z-index: 3;
    background: $colorPrimary;
    transition: all ease .2s;
    @include flex;
    align-items: center;
    justify-content: center;
    color: $colorWhite;
    font-size: 3rem;
    cursor: pointer;
    &.active{
        right: 0;
    }
}
.inner-container{

}
/*1 Col Layout*/
.cms-impressum.page-layout-1column .page-main, body.cms-datenschutz main, .cms-agbs main {
  h1 {
    line-height: 1;
  }
}

.cms-page-view, .cms-index-defaultnoroute{
  .column{
    .inner-container{
      line-height: 2.4rem;
      padding-bottom: 5rem;
      a{
        font-weight: 500;
      }
      h1, h2, h3, h4{
        text-align: left;
        color: $colorPrimary;
        a{
          font-weight: bold;
        }
      }

      ul{
        padding-left: 1.5rem;
        li{
          list-style: disc;
        }
      }
    }
  }
}
.title {
  font-weight: bold;
  line-height: 1;
  font-size: 2.4rem;
  padding-bottom: 1.6rem;
  display: block;
  border-bottom: 1px solid $colorBorder;
}
.column{
  .description {
    table {
      tr {
        td, th{
          font-size: 1.6rem;
        }
        td {
          text-align: left;
        }
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        font-size: 1.8rem;
        line-height: 1;
        font-weight: bold;
        text-align: center;
        &:first-child {
          text-align: left;
        }
      }
      th {
        padding: 1rem 0;
        text-align: center;
        font-size: 1.3rem;
        font-weight: normal;
        &.subtotal {
          text-align: right;
        }
        &.col{
          padding: 0 1rem 1rem;
          border-bottom: 0.1rem solid $colorBorder;
          font-size: 1.25rem;
          font-weight: normal;
        }
        &.subtotal {
        }
        &.item {
          text-align: left;
          padding-left: 0;
        }
        &.action {
          padding: 0;
          width: 24px;
        }
        &.price {
        }
      }
    }
    tbody{
      width: 100%;
      padding: .6rem 0;
      border-bottom: 1px solid $colorBorder;
    }
    tr {
      .control {
        &.size {
          text-align: right;
          display: flex;
          justify-content: flex-end;
          padding-right: 8.2rem;
        }
      }
      td {
        padding: 0;
        text-align: left;
        font-size: 1.3rem;
        &.amount{
          padding: 1rem 0;
        }
        &.col {
          position: relative;
          text-align: left;
          padding: 1rem 1rem 1.1rem;
          display: table-cell;
          vertical-align: top;
          &.item {
            display: block;
            width: 100%;
            a {
              display: table-cell;
              width: auto;
              span {
                /*height: 7.2rem;
                width: 7.2rem !important;*/
                position: relative;
                display: block;
                /*padding-bottom: 0 !important;*/
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  /*height: 7.2rem;*/
                  /*width: 7.2rem;*/
                  /*min-width: 10rem;*/
                  /*height: auto;*/
                }
              }
            }
            .product {
              &-item {
                &-name {
                  a{
                    @extend .product-item-link;
                  }
                }

                &-details {
                  display: table-cell;
                  padding-left: 3rem;
                  vertical-align: top;
                  text-align: left;
                  >span{
                    display: block;
                  }
                }

                &-brand {
                  width: 100%;
                  display: block;
                  font-size: 1.5rem;
                  text-transform: uppercase;

                }
              }

              strong {
                line-height: 1;
              }
            }
          }
          &.price {

            .cart{
              &-price{
                font-weight: normal;
              }
            }
          }
          &.qty {

            .qty{
              text-align: center;
              background: transparent;
              margin: 0;
            }

            label{
              display: inline-block;
            }
            .label {
              display: none;
            }

            input {
              /*&::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button{
                -webkit-appearance: none;
              }
              &[type=number]{
                -moz-appearance: textfield;
              }*/
              display: inline-block;
              @include fontDefault;
              font-weight: normal;
              cursor: pointer;
              padding: 1.2rem .9rem;
              /*padding-right:  2.5rem;*/
              width: 5rem;
              border: 1px solid $colorBorder;
              /*background: url($imageDir + "icons/p294.png") center right 10px no-repeat;*/
              color: $colorPrimary;
            }
          }
          &.subtotal {
            text-align: right;
          }
          &.action{
            padding: 0;

            .action{
              text-align: center;
              display: inline-block;
              &-delete{
                &:before{
                  display: inline-block;
                }
              }
              span{
                height: auto;
                position: inherit;
                overflow: initial;
                padding-top: .3rem;
                display: inline-block;
                width: 100%;
                font-size: 1.4rem;
                font-weight: 300;
                color: $colorPrimary;
              }
            }
          }

        }
      }
    }

    .item {
      &-options {
        margin-top: 1rem;
        font-size: 1.6rem;
        line-height: 2rem;
        @include flexWrap;
        dt{
          width: 17rem;
          font-weight: bold;
        }
        dd{
          width: calc(100% - 18rem);
        }
      }
      &-actions {
        position: relative;
        td {
          padding: 0;
          text-align: right;
          .actions-toolbar {
            @include flex;
            justify-content: flex-end;
            position: absolute;
            bottom: 2.5rem;
            right: 0;
          }
        }
      }
    }
    &.cart.item {
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
    }
  }
  > .inner-container{
    table{
      margin-top: 2.5rem;
      thead {
        th {
          text-align: left;
          font-weight: 500;
          border-bottom: 1px solid $colorBorder;
          padding: .7rem 0;
        }
      }
      tbody{
        border-bottom: 0;
        td{
          text-align: left;
          border-bottom: 1px solid $colorBorder;
          padding: .7rem 0;
        }
      }
    }
  }
}
.new.password{
  width: 100%;
}

/*2 Col Layout*/
.page-layout {
  &-storytelling-layout{
    .products{
      &.wrapper{
        @include contentMedium;
      }
      &.list{
        .product{
          &-item{
            width:19%;
            &:nth-of-type(4n){
              margin-right: 1%;
            }
            &:nth-of-type(5n){
              margin-right: 0;
            }
          }
        }
      }
    }
    .category {
      &-description{

      }
      &-page-header {
        &-text {
          position: relative;
          bottom: initial;
          left: initial;
          transform: none;
          background: transparent;
          @include contentInside;
          padding-bottom: 6.8rem;

          h1 {
            text-align: center;
          }
        }
      }
    }
  }
  &-1column:not(.cms-home){
    .breadcrumbs{

    }
    .column{
      margin-bottom: 8rem;
      color: $colorPrimary;
      &s{
        @include contentMedium;
      }
      font-weight: normal;
    }
    .page-title{
      padding-bottom: 0;
    }
  }
  &-2columns-left {
    .page {
      &-main {
        padding-top: 0rem;


      }
      &-wrapper .columns {
        /*margin-top: 0px;
        @include contentInside;*/
      }
    }

    .columns {
      margin-bottom: 3rem;
      position: relative;
      @include flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      @include contentMedium;
      .column {
        width: calc(75% - 1.5rem)
      }
    }
  }
}

.arrow-down{
  position: absolute;
  bottom: 3rem;
  width: 100%;
  height: auto;
  text-align: center;
  cursor: pointer;
  &:after{
    //content: url($imageDir + "icons/scrolldown.png");
    display: inline-block;
  }

}
.block {
  &-search{
    .block {
      &-title {
        display: none;
      }
      &-content{
        .label{
          display: none;
        }
        .actions{
          display: none;
        }
      }
    }
  }
  &-title {
    @include flexWrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 3rem;

    > * {
      width: auto;
    }

    h2, h3 {
      padding: 0;
      color: $colorPrimary;
      font-size: 2.7rem;
      font-weight: 600;
      line-height: normal;
    }

    h3 {
      color: $colorPrimary;
      padding-left: 1rem;

    }

    .description {
      @include flex;
      justify-content: center;
      width: 100%;
      padding: 3.8rem 0;

      p {
        color: $colorSecondary;
        max-width: $mediumContent;
        text-align: center;
        @include fontDefault;
      }
    }
  }
}

.category-page-header {
  position: relative;
  background: $colorBeige;
  margin-top: 10rem;
  padding: 4.5rem 0;
  margin-bottom: 6rem;
  .category{
    &-image{
      width: 100%;
      @include flex;
      justify-content: center;
      align-items: center;
      img{
        width: 100%;
      }
    }
    &-description{
      position: relative;
      z-index: 1;
      max-width: 50rem;
      display: inline-block;
      font-size: 1.6rem;
      color: $colorSecondary;
      line-height: normal;
    }
  }
  &-text {
    padding: 0;

    text-align: center;
    margin-bottom: 3rem;
    h2{

    }
  }
  .page-title-wrapper{
    width: auto;
    max-width: 100%;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-bottom: 1.3rem;
    line-height: normal;


    @include flex;
    align-items: center;
    justify-content: center;
    h1{
      line-height: normal;
      margin-bottom: 0;
      text-align: center;
      color: $colorPrimary;
      padding: 0;


    }
  }

}
#webcrossing-carousel-1 .slick-track {
  width: 100% !important;
  .slick-slide {
    width: 100% !important;
  }
}
.cms-home{
  .page {
    &-wrapper{
      .page-main{
        padding-top: 0;
      }
    }
    &-title {
      padding-top: 15rem;
      padding-bottom: 15rem;
    }
  }
}
main{
  > .page-title{
    &-wrapper{
      @include contentMedium;
    }
  }
}
.page-title {
  text-align: left;

  h2{
    padding: 0;
    line-height: 7.2rem;
    text-align: center;
  }
  &-wrapper {
    text-align: left;
    width: 100%;
    h1 {

      color: $colorPrimary;
    }

  }
}

.becomeamember {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  top: 0;

}
.special_price + .new{
  margin-left: 1rem;
}
.special_price, .new{
  display: inline-block;
  .text{

    background-size: 100% 100%;
    color: $colorWhite;
    font-size: 1.6rem;
    font-weight: bold;
    padding: .5rem 1rem;
    text-align: center;
  }
}

.new{
  .text{


  }
}
.cms-page-view, .catalog-product-view, .checkout-cart-index{

  .header{
    &-main{
      -webkit-box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
      -moz-box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
      box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
      &-container{

      }
    }
  }
  .page{
    &-main{
      /*padding-top: 10rem;*/
    }
    &-header{

    }
  }
}
.products.wrapper + .toolbar{
  justify-content: center;
  .pages{
    display: inline-block;
    width: 100%;
  }
  .limiter,  .sorter{
    display: none;
  }
}
.toolbar {
  margin: 2rem 0;
  background: $colorLightGrey;
  @include flexBetween;
  .limiter, .sorter{
    padding: 2rem;
    @include flexCenter;
    position: relative;
    label{
      color: $colorPrimary;
      font-weight: normal;
    }
  }
  select {
    /*-moz-appearance:none; !* Firefox *!
    -webkit-appearance:none; !* Safari and Chrome *!
    appearance:none;*/
    @include fontDefault;
    width: auto;
    font-weight: normal;
    cursor: pointer;
    padding: 0.7rem 0.5rem;
    color: $colorPrimary;
    background: transparent;
    position: relative;
    margin: 0 1rem;
    background: $colorWhite;
    border: 1px solid $colorBorder;
    z-index: 2;
  }
  option{
    @include fontDefault;
    font-size: 1.6rem;
    line-height: normal;
    font-weight: normal;
  }
  .limiter{
    label{
      display: none;
    }
  }
  .sort {
    &-asc {
      &:before {
        content: "#{$iconSortAsc}";
      }
    }
    &-desc {
      &:before {
        content: "#{$iconSortDesc}";
      }
    }
    &er {
      &-action {
        &.action {
          padding: 0;
          border:0;
        }
        &:before {
          @include fontIcon;
        }
        span {
          display: none;
        }
      }
    }
  }
  &.customer-addresses-toolbar {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: right;
    margin: 0;
    width: 100%;
  }
  /*Toolbar Sorter*/
  &-sorter {
    text-align: right;
    align-items: center;
    z-index: 1;
    .sorter {
    }
  }
  .pages {
    display: none;
  }
}
.filter {
  @include contentInside;
  padding: 0;
  &-actions {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 1.5rem 0 3rem;
    border-bottom: 1px solid $colorBorder;
    a {
      width: 100%;
      display: inline-block;
    }
  }
  &-current {
    display: inline-block;
    width: 100%;
    text-align: left;
    padding: 1.5rem 0;

    &-subtitle {
      display: none;
    }

    ol {
      justify-content: flex-start;
      @include flex;
      li {
        border-right: 1px solid $colorBorder;
        &:last-child {
          border: 0;
          padding-right: 0;
          .filter {
            &-label {
            }
          }
        }
        .filter {
          &-label {
            width: 100%;
            font-weight: 700;
            font-size: 1.3rem;
            padding-bottom: .5rem;
            text-align: left;
          }
          &-value {
            display: inline-block;
            padding: 0 .8rem;
            border: 1px solid $colorBorder;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 3.8rem;
          }
        }
        a, span {
          display: inline-block;
          &.remove {
            padding: 0;
            margin: 0;
            span {
              display: none;
            }
            &:before {
              content: "\f410";
              @include fontIcon;
            }
          }
        }
      }
    }
  }
}
.pages{
  background: $colorLightGrey;
  > strong {
    display: none;
  }
  ul {
    @include flexCenter;
    padding: 2rem;
    li {
      @include flex;
      align-items: center;
      justify-content: center;
      &.item{
        margin: 0 1rem;
        a, strong {
          font-size: 1.5rem;
          padding: 0.5rem;
          text-align: center;
          font-weight: normal;
          @include flex;
          align-items:  center;
          border:0;
          span{
            display: inline-block;
            width: 100%;
            text-align: center;
          }
        }
        .label{
          display: none;
        }
      }
      &.pages{
        &-item {
          &-previous, &-next{
            a {

              &:before{
                content: "#{$iconPagination}";
                @include fontIcon;
                text-align: center;
                display: inline-block;
                width: 100%;
                font-size: 1.5rem;
              }
            }
          }
          &-previous {
            a {
              &:before{
                transform: rotate(180deg);
              }
              span {
                display: none;
              }
            }
          }
          &-next{
            a{
              &:before{
              }
              span {
                display: none;
              }
            }
          }
        }
      }
      &.current{
        strong {
          background: $colorSecondary;
          color: $colorWhite;
        }
      }
    }
  }
}
.green{
  color: $colorPrimary !important;
}
/*Page*/
.page-wrapper {
 /* margin-top: 16rem;*/

  /*Breadcrumbs*/
  .page-main{
    padding-top: 4.5rem;
  }
  .block.block-addresses-list + div {
    display: flex;
  }
  /*Columns*/
  .columns {
    .main {

      /*Toolbar*/

      .products-grid + .toolbar-products .pages,
      .posts-grid + .toolbar-posts .pages{
        display: block;
        margin-bottom: -3rem;
        + .toolbar-sorter {
          display: none;
        }
      }
      .products-grid + .toolbar-products .toolbar-sorter {
        display: none;
      }
      .products-grid + .toolbar-products {
        margin: 3rem 0 5rem;
      }
      .category-image {
        @include flexCenter;
        img {
          width: 100%;
        }
      }
      .category-description {
        padding-bottom: 3rem;
      }

      /*old & special Price*/
      .price-final_price {
        .percentage {
          color: $colorPrimary;
          @include fontSmaller;
          margin-right: 1.5rem;
        }
        .special-price {


          .price{
            font-weight: bold;
          }
          .price-label {

          }
        }
        .old-price {
          @include flexCenter;
          color: $colorSecondary;
          opacity: .5;

          span {
            @include fontSmaller;
          }
          .price-label {

          }
        }
      }
    }
  }
  .block.filter {
   /* display: none;*/
  }
  .page-sharing-wrapper {
    position: absolute;
    top: -3rem;
    right: 0;
  }
}

.product-grid.three {
  .products.list {
    justify-content: center !important;
  }
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
  padding: 0 !important;
}
.error404 {
  .page-info-main {
    text-align: center;
  }
}
.widget.magento-widget.category-overview {
  a.action.tocategory.secondary {
    border-color: #000;
    color: #000;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    &:hover {
      box-shadow: 0 0 1px 1px #000;
    }
  }
}
.wishlist-index-index {
  .page-wrapper {
    .columns {
      .main {
        .products.products-grid {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 4.5rem;
          margin-bottom: 2rem;
          margin-left: 0;
          margin-right: 0;
          .products.list {
            margin-bottom: 0.5rem;
          }
        }
        .actions-toolbar > .primary {
          margin-right: 0.6rem;
        }
      }
    }
  }
}
.widget-content {
  margin-top: -5rem;
}
.widget-title {
  position: relative;
  max-width: 134rem;
  margin: 0 auto;
  margin-bottom: 8rem;
  .title-link {
    text-align: right;
    display: block;
    @include fontSmaller;
    font-weight: bold;
    text-decoration: underline;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: -8rem;
  }
}
.column {
  width: 100%;
}

.customer-account-logoutsuccess, .archive {
  .main {
    padding-top: 4rem;
  }
}

.privacy-policy {
  .widget.elementor-widget.text-teaser.small.left {
    margin-bottom: 0;
  }
}
.elementor-widget:not(:last-child) {
  margin-bottom: 0px !important;
}
/*Pagination*/
