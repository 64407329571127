/*Product Detail*/
.preview-giftcard{
  background-size: 100% !important;
}

/*.hidden{
  display: none;
}*/
.catalog-product-view {
  .modal-popup {
    &._show .modal-inner-wrap {

    }
  }
  button.splide__arrow{
    &.splide{
      &__arrow{
        &--prev{
          left: -8rem;
        }
        &--next{
          right: -8rem;
        }
      }
    }
  }
  .related{
    order:3;
    margin: 4rem 0;
    .product {
      &-item{
        &-actions{
          display: none;
        }
      }
      &s.list .product-item {
        width: calc((100% - 7.5rem) / 6);
        .related{
          display: none;
        }
        &:nth-of-type(4n) {
          margin-right: 1.5rem;
        }

        &:nth-of-type(6n) {
          margin-right: 0;
        }
      }
    }
    .price {
      &-filling-amount, &-details {
        display: none;
      }
    }
    .block{
      &-title{
        border-bottom: 0;
        @include flexCenter;
        strong{
          @include fontTitle;
          font-weight: normal;
          font-size: 2.7rem;
        }

      }
      &-actions{
        display: none;
      }
    }
  }
  .action-skip-wrapper{
    display: none;
  }
  .main{
    @include flexWrap;

    .page-wrapper {
      padding-top: 0;
    }
    .webcrossing-widget-product-slider {
      .block {
        &-title {
          h2 {
            text-align: left;
          }
        }
        &-content{
          .items {
            .item {
              &.content {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

  }
  .product{
    text-align: left;
    &.delivery_time{
      @include flex;
      .value{
        margin-left: 1.5rem;
      }
    }
    &.media{
      order: 0;
      width: 50%;
      .giftcard {
        &-template {
          &-setting{
            display: none;
          }
        }
      }
    }
    &.info{
      order:2;
    }
    &.attribute{
      order:0;

      margin-bottom: 1rem;
      &.sku{
        margin-bottom: 0;
        @include flex;
        .type{
          margin-right: .5rem;
          &:after{
            content: ": ";
          }
        }
      }
      p{
        font-size: 1.8rem;
        margin-bottom: 0;
      }
    }
    &-info{
      &-labels{
        .special_price, .new{
          margin-bottom: 2.3rem;
        }
      }
      &-media {
        max-width: 50rem;
        position: relative;
        width: 100%;
        /*margin-top: 4rem;*/
        margin-bottom: 5.1rem;
        .fotorama {
          &__wrap{
            padding-bottom: 5rem;
          }
          &__img {
            top: 0;
            transform: translate3d(-50%, 0, 0);
          }
          &__thumb-border{
            border-color: $colorPrimary;
          }
        }
        .fotorama__stage__frame.fotorama__active.fotorama_vertical_ratio.fotorama__loaded.fotorama__loaded--img {
          img {

          }
        }
        .fotorama__wrap--toggle-arrows{
          background: transparent;
        }
        .fotorama__arr {
          background: transparent;

          &__arr{
            background: no-repeat center center;
            top: 40%;
          }
          &--prev,  &--next{
            margin-top: 8rem;
            transition: all ease .2s;
            position: absolute;
          }
          &--prev {
            .fotorama__arr__arr{

              background-image: url($imageDir + "arrow_slide_left.svg");
            }

          }
          &--next {
            .fotorama__arr__arr{
              background-image: url($imageDir + "arrow_slide_right.svg");
            }
          }
        }
        .action.skip {
          display: none;
        }
        .gallery-placeholder div {
          /*max-height: 446px;*/
        }
        .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
          position: absolute;
          bottom: 0.9rem;
        }
        .fotorama__nav--dots .fotorama__nav__frame {
          height: inherit;
          width: inherit;
        }
        .fotorama__dot {
          width: 6.5rem;
          height: 4px;
          top: inherit;
          left: inherit;
          border-radius: 0;
          border: 0;
          margin-left: 1.3rem;
          margin-right: 1.3rem;
          cursor: pointer;
          background-color: rgba($colorPrimary, .3);
        }
        .fotorama__active .fotorama__dot {
          background-color: $colorPrimary;
        }
        .fotorama__fullscreen-icon:focus:after, .fotorama__zoom-out:focus:after, .fotorama__zoom-in:focus:after, .fotorama__arr:focus:after, .fotorama__stage__shaft:focus:after, .fotorama__nav__frame--thumb:focus .fotorama__thumb:after, .fotorama__nav__frame--dot:focus .fotorama__dot:after {
          box-shadow: none !important;
        }
        .fotorama__stage:before, .fotorama__nav:before, .fotorama__stage:after, .fotorama__nav:after {
          display: none !important;
        }
        a.action.skip.gallery-next-area, .action-skip-wrapper {
          display: none;
        }
        .giftcard {
          &-template {
            &-setting{
              display: none;
            }
          }
        }
      }/* End -media */
      &-container{

        @include flexBetween;
        padding-top: 4.5rem;
        padding-bottom: 3rem;
      }
      &-inner{
        &-container{
          font-size: 1.5rem;
        }
      }
      &-main {
        width: 40%;
        padding-left: 4.5rem;
        /*margin-top: 4rem;*/
        line-height: 2.7rem;
        padding-bottom: 6rem;
        display: flex;
        flex-direction: column;
        order:1;
        font-size: 1.4rem;
        font-weight: normal;
        .inner-container{

        }
        .page-title{
          padding: 0;
          width: 100%;
          line-height: 1;
          text-align: left;
          margin-bottom: 2rem;

          @media only screen and (max-width: #{$mediumSmallBreakpoint}) /* 768px */ {
            padding-bottom: 1.5rem;
          }
          span.price-label {
            display: none;
          }
          .swatch-attribute-label {
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            display: block;
            line-height: 1;
            padding-bottom: 1.3rem;
          }
          &-wrapper{
            padding: 0;
            max-width: 100%;
            margin: 0;
            background: transparent;
            text-align: left;
            order: 0;
            .short_description{
              line-height: 2.2rem;
              font-weight: 400;
            }
          }
        }
      }/* End -media */
      &-category {
        ul {
          @include flex;
          li {
            a.action.tocategory.secondary {
              margin-right: 2rem;
              border-color: #C8C8C8;
              color: #000;
              &:hover {
                box-shadow: 0 0 1px 1px #000;
              }
            }
            &:first-child {
              a {

              }
            }
          }
        }
      }/* End -category */
      &-new {
        position: absolute;
        right: 19.5rem;
        top: -2rem;
        /*background-image: url($iconDir + "new_big.svg");*/
        @include icon;
        width: 11.5rem;
        height: 11.5rem;
        span {
          display: none;
        }
      }/* End -new */
      &-special-price {
        position: absolute;
        right: 19.5rem;
        top: -2rem;
        /*background-image: url($iconDir + "sale_big.svg");*/
        @include icon;
        width: 11.5rem;
        height: 11.5rem;
        span {
          display: none;
        }
      }/* End -special-price */
      &-icon {
        a {
          @extend .emptyButton;
          color: transparent;
          border-radius: 0;
          span {
            display: none;
          }
        }
      }/* End -icon */
      &-brand {
        color: $colorSecondary;
        font-size: 2.5rem;
        text-align: left;
        font-weight: 300;
        strong {
          font-weight: normal;
        }
      }/* End -brand */
      &-spice_type {
        padding-bottom: 2.5rem;
        strong {
          @include fontLarger;
        }
      }/* End -spice_type */
      &-short{
        &-description{
          margin-bottom: 3rem;
          font-weight: 400;
        }
      }
      &-description{
        padding: 0 0 5rem;
        color: $colorPrimary;
        border:0;
        font-size: 1.3rem;
        line-height: 2.2rem;

        h3{
          color: $colorSecondary;
          margin-bottom: 4.5rem;
          font-size: 3.6rem;

        }

      }
      &-detail{
        padding: 0 0 1rem;
        .details{
          ul{
            @include flex;
            justify-content: space-between;
            li{
              width: 31%;
              background: $colorGrey;
              padding: 4rem 5rem;
              color: $colorSecondary;
              font-size: 1.8rem;
              h3{
                text-align: left;
                margin-bottom: 2.5rem;
                font-weight: bold;
                font-size: 2.3rem;

              }
            }
          }

        }
      }
      &-stock{
        &-sku{
          order: 4;
          @include flexBetween;

        }
      }
      &-price {
        order:1;
        /* margin-bottom: 3rem;*/
        @include flexWrap;
        flex-direction: column;
        @media only screen and (max-width: 768px) {
          padding-bottom: 3rem;
        }
        .member, .normal {
          &-price {
            .price{
              &-label{
                min-width: 8rem;
              }
            }
          }
        }
        .member{
          &-price{
            .price{
              font-size: 4rem;
            }
          }
        }
        .price {
          &-label {

          }
          &-info-sku{
            font-size: 1.3rem;
          }
          &-filling-amount{
            font-size:1.1rem;
            margin: 0 0 1rem 0;
            line-height: normal;
          }
          &-details{
            font-size: 1.1rem;
            line-height: normal;
            padding-bottom: 1rem;
            border-bottom: 1px solid $colorBorder;
            margin-bottom: 1rem;
            a{
              border-bottom: 1px solid $colorPrimary;
            }
          }
          &-per-amount{
            font-size: 1.1rem;
          }
          &-final_price {
            font-size: 2rem;
            line-height: normal;
            font-weight: bold;
            padding-bottom: 1rem;
            span{
              .price-label{

              }
            }
          }
          &-box.price-final_price {
            .percentage {
              margin-right: 2rem;
            }

          }
        }
        .sale-price{
          order: 1;
        }
      }
      &-inner-container{
        font-size: 1.3rem;
      }
    }/* End -info */
    &-add {
      &tocart-popup{
        display: none;
      }
      &-form {
        /*padding-right: 4.5rem;*/
        order: 1;
        margin-top: 2rem;
        .swatch-opt {
          display: flex;
          flex-direction: column;

          .swatch-attribute.size {
            order: 1;
            margin-bottom: 2.3rem;
          }

          .swatch-attribute.color {
            order: 0;
            .color{
              @include transition;
              opacity: 0.6;
              &:hover{
                opacity: 1;
              }
            }

            .selected{
              opacity: 1;
              border: 2px solid $colorSecondary;
            }
          }
        }
        .swatch-attribute {
          &.color {
            padding: 2rem 0;
            @media only screen and (max-width: 768px) {
              padding-bottom: 4.5rem;
            }
          }

          &-label {
            margin-bottom: 1.3rem;
            font-weight: 400;
            display: inline-block;
            width: 100%;
            line-height: normal;
            display:  none;
          }

          &-options {
            @include flex;
            align-items: flex-start;

            .swatch-option {
              &.image {
                border-radius: 0;

                width: auto;
                height: auto;
                padding: 0;
                /*&:after{
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba($colorBlack, 0.3);
                  content: "";
                }*/
                img {
                  border: 1px solid $colorBorder;
                  position: relative;

                }

                .label {
                  width: 100%;
                  display: block;
                  font-style: italic;
                  font-size: 1rem;
                  margin-top: 0.5rem;
                  line-height: normal;
                  border: 1px solid $colorWhite;

                }

                &.selected {
                  border: 0;

                  img {
                    border: 2px solid $colorBlack;

                  }
                }
              }

              &.text {
                width: auto;
                border: 0.5px solid rgba($colorSecondary, 0.2);
                height: 3.6rem;
                border-radius: 0;
                @include flexCenter;
                margin-right: 1.2rem;
                font-size: 1.4rem;
                min-width: 3.5rem !important;
                padding: 0 1rem;
                line-height: normal;
                &.selected {
                  border: 0.5px solid $colorPrimary;
                  background: $colorPrimary;
                  color: $colorWhite;
                }
              }
            }

          }
        }
        .box-tocart {
          /*padding-top: 4.5rem;
          padding-bottom: 3rem;*/

          .fieldset {
            width: 100%;
            @include flex;

            .field.spice_amount {
              width: calc(100% - 32rem);
            }
            .field.qty {
              width: auto;
              @include flexCenter;
              label {
                letter-spacing: 0.09rem;
                margin-right: 0.7rem;
              }
              .control {
                @include flexWrap;
                align-items: center;
                margin:0;

                input {

                }
                .remove, .add {
                  cursor: pointer;
                  width: 3rem;
                  text-decoration: none;
                  text-align: center;
                  font-size: 2rem;
                  line-height: 1;
                  font-weight: normal;
                  border: 0;
                  color: $colorPrimary;
                  background: transparent;
                  padding: 0;
                }

                .remove {
                  content: "-";
                }

                .add {
                  content: "+";
                }
                .input-text.qty {
                  @include fontDefault;
                  text-align: center;
                  text-decoration: none;
                  line-height: normal;
                  padding-top: 1.2rem;
                  padding-bottom: 1.2rem;
                  color: $colorPrimary;
                  background: transparent;
                  width: auto;
                  max-width: 5rem;
                  border:1px solid $colorBorder;
                }
              }
            }

            .actions {
              margin-top: 0;
              /*min-width: 19rem;*/
              min-height: 0;
              margin-left: 1rem;
              @include flexEnd;

              button {
                margin-right: 0;
                @include font;
                line-height: normal;
                text-transform: normal;
                /*border: 1px solid $colorPrimary;*/
              }
            }
          }
        }

        .giftcard {
          &-template {
            &-container {
            }

            &-preview {
              max-width: 8rem;
              display: none;

              > div {
                max-width: 100%;
              }
            }

            &-setting {
              padding-bottom: 5rem;

              .block-title {
                margin-bottom: 1.4rem;
              }

              &-select {
                > div {
                  @include flex;
                }
              }
            }

            &-preview {

            }
          }

          &-design {
            &-button {
              &-container {
                margin-right: 1.2rem;
                max-width: 8rem;

                i {
                  line-height: normal;
                }

                img {
                  border: 2px solid $colorWhite;
                }

                span {
                  width: 100%;
                  display: block;
                  font-style: italic;
                  font-size: 1rem;
                  margin-top: 0.5rem;
                  line-height: normal;
                  border: 1px solid #fff;
                }

                button {
                  border: 0;
                  background: transparent;
                  @include fontDefault;
                }

                &.active {
                  img {
                    border: 2px solid $colorPrimary;
                  }

                }
              }
            }
          }
        }
      }

      /* End -add-form */
    }
    &-info {

    }
    &.detailed {
      width: 100%;
      padding-top: 3rem;

      .items {
        position: relative;
        .item {
          &.title {
            float: left;
            width: auto;
            padding: .4rem 0;
            font-weight: bold;
            font-size: 1.4rem;
            padding-right: 1rem;
            border-bottom: 0;
            line-height: normal;
            text-align: center;
            a {
              display: block;
              position: relative;
              z-index: 2;
              min-height: auto;
              box-sizing: border-box;
              cursor: pointer;
              padding: 1rem 1.5rem;
              min-width: 14rem;
              text-align: center;
              font-weight: bold;
              background: $colorWhite;
              border: 1px solid $colorSecondary;

              font-size: 1.4rem;

            }
            &.active {
              a {
                background: $colorSecondary;

                border-bottom: 0;
                color: $colorWhite;
              }
              &+.content .inner-content {
                opacity: 1;
              }

            }
            &:first-child a {
              /*padding-left: 0;*/
            }
            &:last-child a{
              /*padding-right: 0;*/
            }
          }
          &.content {
            float: right;
            margin-left: -100%;
            width: 100%;
            margin-top: 4.2rem;
            border: 1px solid $colorSecondary;
            padding: 2rem;
            @include fontDefault;
            &.active {
              display: block;
            }
            .inner-content {
              opacity: 0;
              margin: 0 auto;
              font-size: 1.5rem;
              line-height: 2.5rem;
              .label{
                display: inline-block;
                font-weight: bold;
              }
              &.facts, &.awards{
                .label{
                  min-width: 200px;
                  width: auto;
                }
                .value{
                  display: inline-block;
                }
                ul{
                  li{
                    @include flex;
                  }
                }
              }
              &.details{
                ul{
                  li{
                    margin-bottom: 1.5rem;
                    &:last-child{
                      margin-bottom: 0;
                    }
                    .label{
                      width: 100%;

                    }
                  }
                }
              }
              ul{
                margin-top: 0;
                li{

                }
              }
              &.care_instruction{
                ul{
                  li{
                    list-style: inside;
                  }
                }
              }

              &.features, &.material{
                ul{

                  li{
                    width: 100%;
                    @include flex;
                    margin-bottom: 1rem;
                    .image{
                      width: 5rem;
                    }
                    .text{
                      width: 100%;
                      padding-left: 2rem;
                    }
                  }
                }
              }
              &.columns {
                @include flexBetween;
                flex-direction: row;
              }
              .left{
                width: 100%;
                max-width: 23.6rem;
                font-weight: bold;
              }
              .right {
                width: 100%;
                max-width: 30.5rem;
                ul {
                  li {
                    @include flexBetween;
                  }
                }
              }
              &.awards{

              }
              @media only screen and (max-width: #{$smallBreakpoint}) {
                flex-wrap: wrap;
                .left, .right {
                  max-width: inherit;
                  width: 100%;
                }
              }
              .inner:not(:last-child){
                padding-bottom: 2.5rem;
              }
            }
          }
        }
      }
    }

    &.attribute.description {
      font-size: 1.6rem;
      padding-bottom: 3rem;
      line-height: 2rem;
      /*max-width: 54rem;*/
    }
    &-options{
      &-stock, &-shipping, &-sku, &-country, &-size-chart, &-stock-status{
        font-size: 1.2rem;
      }
      &-size-chart{
        padding-bottom: 2rem;
        text-decoration: underline;
        > div{
          display: none;
        }
        span{
          cursor: pointer;
        }
      }
      &-stock-status{
        span{
          i{
            color: #1A9B37;
          }
        }
      }
    }
  } /* End .product */
}
.member, .normal{
  &-price{
    width: 100%;
    .price{
      font-weight: bold;
      color: $colorSecondary;
      display: inline-block;
      &-container{
        width: 100%;
        @include flex;
        align-items: center;

      }
      &-wrapper{
        font-size: 1.5rem;
      }
      &-label{
        font-size: 1.5rem;
        font-weight: 300;
        color: $colorSecondary;
        display: inline-block;
      }
    }
  }
}


/*Galerie*/
/*.gallery-placeholder._block-content-loading {
  background-image: url($imageDir + "loader.gif");
  bottom: 0;
  height: 6.4rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 6.4rem;
  z-index: 100;
}
.gallery-placeholder._block-content-loading .gallery-placeholder__image {
  opacity: 0;
}
.gallery-placeholder__image {
  width: 100%;
}*/
