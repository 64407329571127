/*Sidebar*/
.account .sidebar-main, .page-products .sidebar-main {

}

.nav-sections{
  background-color: $colorLightGrey;
  @media screen and (max-width: 1023px) {
    .navigation {
      position: relative;
      ul {
        @include flexBetween;
        overflow-x: auto;
        padding: 1rem;
        li {
          min-width: fit-content;
          font-weight: 500;
          &:not(:first-of-type) {
            margin-left: 1.2rem;
          }
          &:last-child {
            padding-right: 1.2rem;
          }
          .submenu {
            display: none !important;
          }
        }
      }
      &.shadow-right:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 40px;
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        pointer-events: none;
      }
    }
  }
  &-item {
    &-title {
      display: none;
    }
    &-content{
      .language, .header.links{
        display: none;
      }
    }
  }
}
#store.settings{
  display: none;
}

.navigation{
  @media screen and (min-width: $mediumBreakpoint) {
    ul {
      @include flexCenter;
      width: 100%;
      padding: 0 !important;
      li {
        &.level0 {
          > .level-top {
            display: inline-block;
            padding: 1.5rem 2rem 1.5rem 1.2rem;
            color: $colorPrimary;
            text-decoration: none;
            font-weight: 700;
          }
          a {
            display: block;
          }
          .submenu {
            display: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .page-products .sidebar{
    margin-top: 4rem;
    width: 100%;
  }
}
