.webcrossing {
  &-widget{
    &-textimage-triple{
      margin: 4rem 0;
      display: block;
      @include contentMedium;
      .block-content{
        @include flex;
      }
      .textimage{
        position: relative;
        margin-right: 4.5rem;
        overflow: hidden;
        @include transition;
        &:hover{

        }
        h2{
          @include font;
          text-align: center;
          font-weight: 700;
          margin-bottom: 1.7rem;

        }
        &:last-child{
          margin-right: 0;
        }
        > .text{
          text-align: center;
          padding: 2.5rem 0;
        }
      }
      .image{
        position: relative;
        line-height: 0;
      }
      .text{
        padding: 0 2.5rem 2.2rem;
        font-size: 1.7rem;
      }
      .action.continue{
        display: inline-block;
        border:0;
        padding: 0 {
          bottom: 1.7rem;
        };
        border-bottom: 4px solid $colorPrimary;
        text-transform: uppercase;
        font-size: 1.6rem;
        letter-spacing: 1.5px;
        &:hover{
          background: transparent;
          color: inherit;
        }
      }
    }
    &-image-slider {
      .splide {
        &__slide {
          display: flex;
          flex-direction: column;
          @media screen and (min-width: $mediumBreakpoint) {
            flex-direction: row;
          }
          .text {
            padding: 0 2.5rem 2.2rem;
            font-size: 1.7rem;
            line-height: 1.6;
            @media screen and (min-width: $mediumMaxBreakpoint) {
              max-width: 20vw;
              padding: 0;
              margin-bottom: 2rem;
            }
            &-container {
              height: 100%;
              padding: 2.5rem 0;
              margin: 0 2rem;
              text-align: center;
              @media screen and (min-width: $mediumBreakpoint) {
                @include flexCenter;
                flex-direction: column;
                position: absolute;
                top: 0;
                max-width: 25vw;
                margin: 0 8rem;
              }
              h2 {
                font-family: 'Roboto', sans-serif;
                text-align: center;
                font-weight: 700;
                margin-bottom: 1.7rem;
                @media screen and (min-width: $mediumBreakpoint) {
                  font-size: 4rem;
                  padding-bottom: 2rem;
                }
              }
              &.white {
                @media screen and (min-width: $mediumBreakpoint) {
                  * {
                    color: $colorWhite;
                  }
                  .action {
                    &::after {
                      border-color: $colorWhite;
                    }
                  }
                }
              }
            }
          }
          .action {
            display: inline-block;
            border: 0;
            padding: 0 0 1.7rem 0;
            border-bottom: 4px solid $colorPrimary;
            text-transform: uppercase;
            font-size: 1.6rem;
            letter-spacing: 1.5px;
            &:hover {
              background: none;
              color: inherit;
            }
            @media screen and (min-width: $mediumBreakpoint) {
              border: none;
              &:after {
                content: '';
                display: block;
                max-width: 10vw;
                margin: 10px auto 0;
                border-bottom: 5px solid $colorPrimary;
              }
            }
          }
          .image-container {
            order: -1;
            width: 100%;
            .main {
              display: none;
            }
            .mobile {
              display: block;
              margin: 0 auto;
            }
            @media screen and (min-width: $mediumBreakpoint) {
              order: 0;
              .main {
                display: block;
              }
              .mobile {
                display: none;
              }
            }
          }
          .overlay-link {
            position: absolute;
            display: block;
            height: 100%;
            width: 100%;
            z-index: 2;
          }
        }
      }
    }
    &-category-overview {
      .block-content {
        @media screen and (min-width: $mediumBreakpoint) {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
        }
      }
      .category {
        margin-bottom: 1.5rem;
        @media screen and (min-width: $mediumBreakpoint) {
          flex-basis: 49%;
        }
      }
    }
  }
  &-base, &-widget {
    &-banner-twohalfs, &-product-slider {
      margin: 4rem 0;
      display: block;
      @include contentMedium;
    }
    &-product-slider{
      @media screen and (max-width: $mediumSmallBreakpoint) {
        .splide {
          &__list {
            width: unset;
            .special-price {
              .price-label {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
      .block-title{
        text-align: center;
        justify-content: center;
        h2{
          font-weight: normal;
        }
      }
      .toolbar{
        background: transparent;
      }
      .splide{
        width: 100%;
        &__arrows{
          display: none;
        }
      }
      .product{
        &-item{
          &-wrapper{
            transform: scale(1);
          }
        }
      }
    }
  }
}
.banner-headline-wrapper {
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5rem 10rem 1.7rem;
  &:before {
    position: absolute;
    @include flex;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  span{
    display: inline-block;
    width: 100%;
  }
  h2, h3 {
    position: relative;
    text-align: center;
    /*line-height: 4rem;
    font-size: 4rem;*/

  }
}

.category-ueber-uns{
  .webcrossing{
    &-widget {
      &-image-text{
        &:nth-of-type(even){
          flex-direction: row-reverse;
          &:after{
            right: 0;
            left: inherit;
          }
        }
      }
    }
  }
}