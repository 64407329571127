.checkout-cart-index .title {
  font-weight: bold;
  line-height: 1;
  font-size: 2rem;
  padding-bottom: 1.6rem;
  display: block;
  border-bottom: 1px solid $colorSecondary;
}
.cart {
  &-price {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 0;
  }
  &-sidebar{
    margin-top: 2.8rem;
  }
  &-membership{
    padding: 2.1rem 3rem;
    background: rgba($colorPrimary, 0.15);
    position: relative;
    margin-top: 5rem;
    font-size: 1.6rem;
    h3{
      text-align: left;
      font-size: 2rem;
    }

    &:after{
      position: absolute;
      width: 8rem;
      height: 9rem;

      bottom: 0;
      right: 0;
      content: "";
      /*background: url($imageDir + "group2.png") no-repeat top left;*/
      background-size: 12rem 12rem;
    }
  }
  &-container {
    /* padding-top: 5rem;*/
    /*@include contentInside;*/
    padding-bottom: 28rem;
    @include flexStart;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    #gift-options-cart {
      display: none;
    }

    form {
      /*width: 95rem;*/

      &.form-cart{
        width: calc(75% - 3rem);
      }


      .actions {
        padding-top: 2rem;
        @include flexEnd;
        .action {
          margin-left: 2rem;
          margin-right: 0;
          width: 33%;
          text-align: center;
          font-size: 1.4rem;

          &.continue {
            margin-left: 0;
          }
        }
        &-toolbar {
          align-items: center;
          .action{
            &:hover{
              background: transparent;
              color: $colorPrimary;
            }
          }
          .towishlist span {
            display: none;
          }
        }

        &-edit {
          display: none;
        }

      }
    }

    .shipping {
      display: none;
    }

    caption.table-caption {
      display: none;
    }
  }

  &-summary {
    width: calc(25% - 3rem);
    .load{
      &ing-mask {
        position: absolute !important;
        background: rgba($colorWhite, .9);
        margin: 0;

      }
    }
    .summary {

    }
    .checkout{
      margin-top: 1.6rem;
      &.methods{
        li{
          width: 100%;
          margin-right: 0;
        }
      }
      .action{
        margin-top: 0;
        margin-right: 0;
      }
    }
    button, .primary {
      width: 100%;
    }
    .block {
      &.discount {
        cursor: pointer;
        margin-top: 1.6rem;
        color: $colorPrimary;
        font-size: 1.6rem;
        padding-bottom: 2rem;
        margin-left: 0 !important;
        float: none !important;
        .title{
          border-bottom: 0;
          font-size: 1.4rem;
          width: 100%;

        }

        .field {
          margin-bottom: 1.5rem;
        }

        .fieldset.coupon {
          label {
            display: none;
          }
        }

        .actions-toolbar {
          width: 100%;
          position: relative;
          left: inherit;
          top: inherit;
          .primary{
            /*background: $colorPrimary;*/
          }
        }
      }
    }
  }

  &-totals {
    padding: 1.6rem 0 0;
    border-bottom: 1px solid $colorBorder;

    .table {
      &-caption {
        text-align: right;
      }
      &.totals {
        width: 100%;
        .table{
          &-wrapper{
            position: relative;
          }
        }
        .totals-tax {
          padding-top: 0;
          color: rgba(0, 0, 0, 0.5);
        }
        th, td {
          line-height: 1;
          padding: 0;
          font-size: 1.6rem;
          padding-bottom: 1.6rem;
          font-weight: normal;
        }
        .sub {

        }
        th.mark {
          text-align: left;
        }
        td.amount {
          text-align: right;
        }
      }
      &-wrapper {
        position: relative;
        tr.grand.totals {
          color: black;
        }
        .load.indicator {
          background-color: rgba($colorLighterGrey, 0.5);
          z-index: 9999;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
            border-radius: 0.5rem;
            height: 16rem;
            width: 16rem;
            bottom: 0;
            box-sizing: border-box;
            content: "";
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
          }
          > span {
            display: none;
          }
        }


        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }

        [data-role="main-css-loader"] {
          display: none;
        }
      }
    }
  }
}